import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import Radio from "antd/es/radio";
import TextArea from "antd/lib/input/TextArea";
import Button from "antd/es/button";
import "antd/es/button/style";
import Switch from "antd/es/switch";
import "antd/es/switch/style";
import React, { useReducer, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { useNavigate } from "react-router-dom";
import { locationsCancelEvent } from "../../../utilities/webAnalytics";
import Restricted from "../../../PermissionsProvider/Restricted";
import styles from "./style.module.scss";
import useGetDisableReasons from "../../../utilities/hooks/useGetDisableReasons";
import ConfirmModal from "../../organisms/ConfirmModal";

function FooterWizardLocation(props) {
  const {
    current,
    steps,
    prevStep,
    handleSaveAsDraft,
    isEditMode,
    handleUpdate,
    locationStatus,
    checked,
    setChecked,
    loading,
    setLoading,
    state,
    businessId,
    setDisableValues,
  } = props;

  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useReducer(
    (prev, next) => ({ ...next }),
    {
      isOpen: false,
      isLoading: false,
      action: "",
    }
  );
  const [form] = Form.useForm();

  const [additionalDetails, setAdditionalDetails] = useState("");
  const [reasonToDisable, setReasonToDisable] = useState({});
  const [cancel_future_shifts, setCancelFutureShifts] = useState(true);
  const [childCheck, setChildChecked] = useState(false);

  const { reasons } = useGetDisableReasons();

  const inFirstSteps =
    steps.findIndex((step) => step === current) < steps.length - 1;

  const onUpdateClick = () => {
    setLoading(true);
    let isUpdateStatus =
      locationStatus === "disabled" && checked === false
        ? false
        : (locationStatus === "live" ||
          locationStatus === "pending" ||
          locationStatus === "rejected" ||
          locationStatus === "draft") &&
          checked === true
          ? false
          : true;
    handleUpdate(isUpdateStatus, checked);
  };

  const onSwitchChange = (checked) => {
    if (checked) {
      setChecked(true);
    } else {
      setChildChecked(checked);
      setConfirmModal({
        isOpen: true,
        action: "disable",
      });
    }
  };

  return (
    <div className={styles["footer-wrapper"]}>
      <div className={"divider"} />
      <div className="step-action-inner">
        <Row>
          <Col xs={24}>
            <div className="steps-action">
              {isEditMode ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Restricted to="disable" resource="location" fallback={<></>}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "2rem",
                      }}
                    >
                      <div style={{ marginRight: 25 }}>
                        {checked
                          ? "Disable this location"
                          : "Enable this location"}
                      </div>
                      <div className="switch-wrapper">
                        <Switch checked={checked} onChange={onSwitchChange} />
                      </div>
                    </div>
                  </Restricted>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={onUpdateClick}
                      type="primary"
                      className="button"
                      loading={loading}
                    >
                      Save updates
                    </Button>
                    <Button
                      type="secondary"
                      className="cancel-button button"
                      onClick={() => {
                        locationsCancelEvent();
                        navigate(
                          state?.mode === "business"
                            ? `/businesses/${businessId}`
                            : "/properties"
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="create-mode-wrapper">
                  {steps.findIndex((step) => step === current) > 0 ? (
                    <Button
                      className="btn-prev button"
                      type="light"
                      onClick={() => prevStep()}
                    >
                      <FeatherIcon icon="arrow-left" size={16} />
                      Previous
                    </Button>
                  ) : (
                    <span></span>
                  )}

                  <div className="save-action">
                    <Form.Item>
                      <Button
                        className="button"
                        type="secondary"
                        onClick={handleSaveAsDraft}
                        disabled={loading}
                      >
                        Save as Draft
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="submit-button button"
                        loading={loading}
                      >
                        {inFirstSteps ? "Save & Next" : "Submit"}
                        <FeatherIcon icon="arrow-right" size={16} />
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      {confirmModal.action === "disable" && (
        <ConfirmModal
          title="Disable this location"
          text={<>
            <div className="mb-24">This will stop this property from creating and completing appointments from all deal types and may incur cancellation fees on upcoming appointments.</div>
            <Form
              form={form}
              name="reasons"
              onFinish={() => { }}
            >
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Select a reason*</p>
              <Form.Item
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please select a reason",
                  },
                ]}
              >
                <Radio.Group>
                  <div className="ant-radio-btn-vertical">
                    {reasons.map((rsn, index) => (
                      <Radio
                        key={rsn.id}
                        value={rsn.id}
                        onChange={(e) => {
                          setReasonToDisable({
                            id: e.target.value,
                            index,
                          })
                        }}
                      >
                        {rsn.description}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Cancel upcoming shifts?*</p>
              <Form.Item
                name="cancel_future_shifts"
              >
                <Radio.Group
                  defaultValue={cancel_future_shifts}
                  value={cancel_future_shifts}
                  onChange={(e) => {
                    setCancelFutureShifts(e.target.value)
                  }}>
                  <div className="ant-radio-btn-vertical">
                    <Radio value={true}>Yes, cancel all shifts with a start time not within 3 days</Radio>
                    <Radio value={false}>No, keep all upcoming shifts</Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Additional Details*</p>
              <Form.Item
                name="additional-details"
                rules={[
                  {
                    required: true,
                    message: "Please add additional details",
                  },
                ]}>
                <TextArea
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  rows={4}
                  style={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}
                  maxLength={255}
                  showCount>
                </TextArea>
              </Form.Item>
            </Form>
          </>}
          buttons={[
            {
              className: "my-10",
              size: "small",
              type: "danger",
              outlined: true,
              text: "Close without saving",
              onClick: () => setConfirmModal({ isOpen: false }),
            },
            {
              size: "small",
              type: "primary",
              loading: confirmModal.isLoading,
              text: "Submit and disable this business",
              onClick: () => {
                form.validateFields().then((values) => {
                  setChecked(childCheck);
                  setDisableValues({
                    cancel_future_shifts,
                    reason_text: additionalDetails,
                    reason_id: reasonToDisable.id
                  })
                  setConfirmModal({ isOpen: false, isLoading: false });
                  form.resetFields();
                })
              },
            },
          ]}
          isOpen={confirmModal.isOpen}
        />
      )}
    </div>
  );
}

export default FooterWizardLocation;
