import React, { useEffect, useState } from "react";

import styles from "./styles.module.scss";

export const EditTeamFormHeader = ({
  fname,
  lname,
  status,
  email,
  disabled,
}) => {
  const [newStatus, setNewStatus] = useState(status);

  useEffect(() => {
    if (disabled === true) {
      setNewStatus("disabled");
    } else if (disabled === false) {
      setNewStatus(status);
    }
  }, [disabled, status]);

  return (
    <div className={styles["edit-team__header"]}>
      <div className={styles["team-info"]}>
        <div className={styles["team-info__name"]}>
          {fname} {lname}
        </div>
        <div className={styles["team-info__email"]}>{email}</div>
      </div>
      <span
        className={`${styles["team-info__status-text"]} ${styles[newStatus]}`}
      >
        {newStatus}
      </span>
    </div>
  );
};
