import Form from "antd/es/form";
import "antd/es/form/style";

import { StyledInput } from "../../atoms";
import styles from "./styles.module.scss";
import PhoneInput from "../../atoms/input/phoneInput";

export const LabeledInput = ({
  label,
  name,
  value,
  onChange,
  onPhoneNumberChange,
  typeMessage,
  placeholder = "",
  type = "text",
  required = false,
  message = "Please fill in this field",
  id,
  defaultValue,
  disabled = false,
  extraRules = [],
  maxLength,
  rows,
  countryCode,
  extraAtTop,
  rules = [],
  ...props
}) => {
  if (id === "phoneNumber") {
    return (
      <Form.Item
        id={id}
        className={styles["generic-label-style"]}
        name={name}
        label={label}
        rules={[
          { type, message: typeMessage || message },
          { required, message },
          ...extraRules,
        ]}
      >
        <PhoneInput
          onChange={onChange}
          countryCode={countryCode}
          onPhoneNumberChange={onPhoneNumberChange}
          value={value}
          type={type}
        />
      </Form.Item>
    );
  } else if (id === "emails") {
    return (
      <Form.Item
        id={id}
        className={styles["generic-label-style"]}
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: "Please provide your email",
          },
          {
            message: "Invalid Email Address(es).",
            validator: (_, value) => {
              if (
                /(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+/.test(
                  value
                )
              ) {
                return Promise.resolve();
              } else {
                return Promise.reject("An Error Occured");
              }
            },
          },
        ]}
      >
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item
        className={styles["generic-label-style"]}
        name={name}
        label={label}
        {...props}
        rules={[
          { type, message: typeMessage || message },
          { required, message },
          ...rules,
          ...extraRules,
        ]}
      >
        <StyledInput
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxLength={maxLength}
          rows={rows}
        />
      </Form.Item>
    );
  }
};
