import { Heading } from "../../atoms/headings";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

export const CopyWithLink = ({
  linkCopy,
  linkTo,
  copy = "",
  additionalClass = "",
  as = "h4",
}) => {
  return (
    <Heading
      as={as}
      className={`${styles["copy-color"]} ${styles[additionalClass]}`}
    >
      {copy}
      <NavLink className={styles["main-cta-link"]} to={linkTo}>
        {linkCopy}
      </NavLink>
    </Heading>
  );
};
