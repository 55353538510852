import React from "react";
import "antd/es/select/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import styles from "./DisableDetailsModal.module.scss";
import useGetDisableDetails from "../../../utilities/hooks/useGetDisableDetails";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import moment from "moment-timezone";

export default function DisableDetailsModal({
  disableable_type,
  disableable_id,
  isOpen,
  onClose,
}) {
  const { disabled_by, name, cancel_future_shifts, reason_text, disable_reason_name, disable_reason_description, disabled_at } = useGetDisableDetails({ disableable_type, disableable_id })

  return (
    <Modal
      title={<div style={{ color: "#272B41", marginBottom: "8px" }}>Disabled Business Details</div>}
      wrapClassName={styles["modal-style"]}
      closable={false}
      centered
      visible={isOpen}
      onCancel={onClose}
      footer={<Button
        size="small"
        type="primary"
        onClick={onClose}
      >
        Close
      </Button>}
    >
      <Row>
        <Col span={12}>
          <div className={styles["bold-dark-text"]}>Disabled by</div>
          <div className={styles["thin-dark-text"]}>{disabled_by}</div>
        </Col>
        <Col span={12}>
          <div className={styles["bold-dark-text"]}>Date</div>
          <div className={styles["thin-dark-text"]}>{moment(disabled_at, "DD-MM-YYYY").format("MMMM Do, YYYY")}</div>
        </Col>
        <Col span={24}>
          <div className={styles["bold-dark-text"]}>Reason</div>
          <div className={styles["thin-dark-text"]}>{disable_reason_description}</div>
        </Col>
        <Col span={24}>
          <div className={styles["bold-dark-text"]}>Cancel Upcoming shifts?</div>
          <div className={styles["thin-dark-text"]}>{cancel_future_shifts ? "Yes, cancel all shifts with a start time not within 3 days" : "No, keep all upcoming shifts"}</div>
        </Col>
        <Col span={24}>
          <div className={styles["bold-dark-text"]}>Additional details</div>
          <div className={styles["thin-dark-text"]}>{reason_text}</div>
        </Col>
      </Row>
    </Modal>
  );
}
