import {axiosInstance as axios, nodePortalAxiosInstance} from "./axiosInstance";

const userEndpoints = {
  profile: "/profile",
  me: "/get_permissions",
  changePassword: "/profile/change_password",
  updateProfileImage: "/profile/update_profile_image",
  checkAppMaintenance: "/maintenance"
};

const user = {
  getProfile() {
    return nodePortalAxiosInstance.get(`${userEndpoints.profile}`);
  },
  getPermissions({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${userEndpoints.me}`, { params: { business_id: id } });
  },
  updateUserProfile(values) {
    return nodePortalAxiosInstance.put(`${userEndpoints.profile}`, values);
  },
  changePassword(values) {
    return nodePortalAxiosInstance.post(`${userEndpoints.changePassword}`, values);
  },
  updateProfileImage(profileImageURL) {
    return nodePortalAxiosInstance.post(`${userEndpoints.updateProfileImage}`, profileImageURL);
  },
  checkAppMaintenance({ queryKey: [, business_id] }) {
    return nodePortalAxiosInstance.get(`${userEndpoints.checkAppMaintenance}`, { params: { business_id } });
  }
};

export default user;
