import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const billingEndpoints = {
  accounting: "/accounting",
  details: "/accounting/details",
  accountingsPaginated: "/accounting/pagination",
  fees: "/cancellation_policies",
  invoices: "/invoices",
  transactions: "/transactions",
};

const billing = {
  getBillings({ queryKey: [, { location_id, business_id }] }) {
    return axios.get(`${billingEndpoints.accounting}`, {
      params: { business_id, location_id },
    });
  },
  getBillingsPaginated({
    queryKey: [, { page, size, location_id, business_id }],
  }) {
    return axios.get(`${billingEndpoints.accountingsPaginated}`, {
      params: { page, size, business_id, location_id },
    });
  },
  getBillingDetails(location_id, business_id, month) {
    return axios.get(`${billingEndpoints.details}`, {
      params: {
        business_id: business_id,
        location_id: location_id,
        month: month,
      },
    });
  },
  getFees({ location_id, business_id }) {
    return nodePortalAxiosInstance.get(`${billingEndpoints.fees}`, {
      params: { business_id, location_id },
    });
  },
  postFees({ cancellation_policies }) {
    return nodePortalAxiosInstance.post(`${billingEndpoints.fees}`, {
      cancellation_policies
    });
    // Payload example:
    // {
    //   "cancellation_policies": [
    //     {
    //       "b2b_type": "staffing",        //<-- required
    //       "time_before_booking": 120,
    //       "client_charge": null,
    //       "client_charge_cents": 10000,
    //       "pro_payout": null,
    //       "pro_payout_cents": 10000,
    //       "cancellation_type_id": 1,
    //       "location_id": 1,
    //       "business_id": 1
    //     },
    //   ]
    // }
  },
  getInvoices({ queryKey: [, { page, size, invoicable_type, invoicable_id, status, billing_period_from, billing_period_to, admin_tab }] }) {
    return nodePortalAxiosInstance.get(`${billingEndpoints.invoices}`, {
      params: { invoicable_type, invoicable_id, page, per_page: size, status, billing_period_from, billing_period_to, admin_tab },
    });
  },
  getTransactions({ queryKey: [, { page, size, invoice_id, status }] }) {
    return nodePortalAxiosInstance.get(`${billingEndpoints.invoices}/${invoice_id}`, {
      params: { page, per_page: size, status },
    });
  },
  addInvoiceItem({ cart_id, transaction_type, property_name, description, amount, invoice_date, invoice_id, location_id }) {
    return nodePortalAxiosInstance.post(`${billingEndpoints.transactions}`, {
      transaction: {
        cart_id,
        transaction_type,
        property_name,
        description,
        amount,
        invoice_date,
        invoice_id,
        location_id,
      }
    });
  },
  voidInvoiceItem({ transaction_id, deleted_comment }) {
    return nodePortalAxiosInstance.post(`${billingEndpoints.transactions}/${transaction_id}/void_transaction`, { deleted_comment });
  },
  updateMemo({ memo, invoice_id }) {
    return nodePortalAxiosInstance.put(`${billingEndpoints.invoices}/${invoice_id}/update_memo`, { memo });
  },
  getTransactionTypes() {
    return nodePortalAxiosInstance.get(`/transactions/transaction_types`);
  },
  showInvoicePDF({ invoice_id }) {
    return nodePortalAxiosInstance.get(`/invoices/${invoice_id}/show_pdf`, { responseType: "blob" });
  },
  updateBillingContacts({ id, emails }) {
    return nodePortalAxiosInstance.post('/invoices/update_billing_emails', {
      id,
      emails
    })
  }
};

export default billing;
