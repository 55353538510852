import React from "react";
import { DropdownBase } from "../atoms";
import Menu from "antd/es/menu";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import teamsProvider from "../../utilities/teamsProvider";
import openNotification from "../../utilities/openNotification";
import {
  teamsShortcutDisableEvent,
  teamsShortcutEditEvent,
  teamsShortcutEnableEvent,
  teamsShortcutResendInviteEvent,
} from "../../utilities/webAnalytics";
import Restricted from "../../PermissionsProvider/Restricted";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";

export const ActionsDropdown = ({
  queryKey,
  team,
  getTeams,
  onDeleteClick,
  userData,
  businessId,
  isDesktop,
  queryClient,
}) => {
  const { permissions } = usePermissions();
  const isSuperAdmin = userData?.role?.isSuperAdmin;

  const resendInviteTeam = {
    email: [team.email],
  };

  const EnableTeam = () => {
    teamsProvider.enableTeam(team.id, businessId, (type, title, msg) => {
      openNotification(title, msg, type);
      if (type === "success") {
        teamsShortcutEnableEvent(team.email);
        queryClient.invalidateQueries("team");
      }
    });
  };

  const DisableTeam = () => {
    teamsProvider.disableTeam(team.id, businessId, (type, title, msg) => {
      openNotification(title, msg, type);
      if (type === "success") {
        teamsShortcutDisableEvent(team.email);
        queryClient.invalidateQueries("team");
      }
    });
  };

  const ReinviteTeam = () => {
    teamsProvider.reinviteTeam(resendInviteTeam, (type, title, msg) => {
      openNotification(title, msg, type);
      type === "success" && teamsShortcutResendInviteEvent(team.email);
    });
  };

  if (isDesktop) {
    return (
      <DropdownBase
        className="actions-dropdown"
        classNameC="actions-dropdown-content"
        disabled={team.id === userData.id}
        action={["click"]}
        content={
          <Menu
            items={[
              {
                label: (
                  <Link
                    onClick={() => teamsShortcutEditEvent(team.email)}
                    to={`/team/${team.id}`}
                    state={{ team: team }}
                  >
                    {(isSuperAdmin ||
                      (!isSuperAdmin && team.role.id !== 1)) && (
                      <Restricted
                        to="update"
                        resource="team"
                        fallback={
                          <>
                            <FeatherIcon icon="eye" size={18} /> View
                          </>
                        }
                      >
                        <FeatherIcon icon="edit" size={18} /> Edit
                      </Restricted>
                    )}
                  </Link>
                ),
                key: "1",
              },
              {
                label: (
                  <Restricted to="resend" resource="team" fallback={<></>}>
                    {(isSuperAdmin || (!isSuperAdmin && team.role.id !== 1)) &&
                      team.disabled === false &&
                      team.status === "invited" && (
                        <Link onClick={() => ReinviteTeam()} to="#">
                          <FeatherIcon icon="send" size={18} />
                          Resend Invitation
                        </Link>
                      )}
                  </Restricted>
                ),
                key: "2",
              },
              {
                label:
                  (isSuperAdmin || (!isSuperAdmin && team.role.id !== 1)) &&
                  (team.disabled === true ? (
                    <Restricted to="enable" resource="team" fallback={<></>}>
                      <Link onClick={() => EnableTeam(team.id)} to="#">
                        <FeatherIcon icon="check-square" size={18} />
                        Enable
                      </Link>
                    </Restricted>
                  ) : (
                    <Restricted to="disable" resource="team" fallback={<></>}>
                      <Link onClick={() => DisableTeam(team.id)} to="#">
                        <FeatherIcon icon="x-square" size={18} />
                        Disable
                      </Link>
                    </Restricted>
                  )),
                key: "3",
              },
              {
                label: (
                  <Restricted to="delete" resource="team" fallback={<></>}>
                    <Link onClick={() => onDeleteClick(team)} to="#">
                      <FeatherIcon icon="trash-2" size={18} />
                      Delete
                    </Link>
                  </Restricted>
                ),
                key: "4",
              },
            ]}
          ></Menu>
        }
      >
        <Link to="#">
          <FeatherIcon icon="more-vertical" size={18} />
        </Link>
      </DropdownBase>
    );
  }

  return [
    <Link
      key="1"
      onClick={() => teamsShortcutEditEvent(team.email)}
      to={`/team/${team.id}`}
      state={{ team: team }}
    >
      <FeatherIcon icon="edit" size={18} />
      Edit
    </Link>,
    ...(isSuperAdmin || (!isSuperAdmin && team.role.id !== 1)
      ? [
          ...(team.disabled
            ? [
                <Link key="2" onClick={() => EnableTeam(team.id)} to="#">
                  <FeatherIcon icon="check-square" size={18} />
                  Enable
                </Link>,
              ]
            : [
                ...(team.role.id !== 1 &&
                !team.disabled &&
                team.status === "invited"
                  ? [
                      <Link key="3" onClick={() => ReinviteTeam()} to="#">
                        <FeatherIcon icon="send" size={18} />
                        Resend Invitation
                      </Link>,
                    ]
                  : []),
                <Link key="4" onClick={() => DisableTeam(team.id)} to="#">
                  <FeatherIcon icon="x-square" size={18} />
                  Disable
                </Link>,
              ]),
        ]
      : []),
    ...(permissions?.team?.includes("delete")
      ? [
          <Link key="5" onClick={() => onDeleteClick(team)} to="#">
            <FeatherIcon icon="trash-2" size={18} />
            Delete
          </Link>,
        ]
      : []),
  ];
};
