import get from "lodash/get";
import { useQuery } from "react-query";
import businessApis from "../api/businesses";
import useTryAutoLogin from "./useTryAutoLogin";

const useGetDisableReasons = () => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, isLoading, refetch } = useQuery(
    ["disableReasons"],
    businessApis.getDisableReasons,
    {
      enabled: !!isLogin,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const reasons = get(data, "data.disable_reasons");
  return { reasons, refetch };
};

export default useGetDisableReasons;
