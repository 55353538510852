/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import Form from "antd/es/form";
import "antd/es/form/style";
import { Skeleton } from "antd";
import FeatherIcon from "feather-icons-react";

import About from "../../About";
import Billing from "../../Billing";
import Discount from "../../Discount";
import Permissions from "../../Permissions";
import useGetBusinessById from "../../../../../utilities/hooks/useGetBusinessById";
import useGetBusinessBilling from "../../../../../utilities/hooks/useGetBusinessBilling";
import businessApi from "../../../../../utilities/api/businesses";
import openNotification from "../../../../../utilities/openNotification";
import billingApi from "../../../../../utilities/api/billing";
import Footer from "./Footer";
import Restricted from "../../../../../PermissionsProvider/Restricted";
import { usePermissions } from "../../../../../PermissionsProvider/PermissionsProvider";
import { StyledInput } from "../../../../atoms";
import VolumeDiscounts from "../../VolumeDiscounts";
import useUserProfileData from "../../../../../utilities/hooks/useUserProfileData";
import { compact, includes, isArray, join, map, some } from "lodash";
import VolumeDiscountsApis from "../../../../../utilities/api/volumeDiscounts";
import locationApi from "../../../../../utilities/api/location";
import CancellationFees from "../../CancellationFees";

const Notes = ({ setNotes, notes }) => {
  return (
    <Form.Item
      colon={false}
      name={`notes`}
      label="Property Notes (Optional)"
      rules={[
        {
          max: 250,
          message: "Note must be maxmum 250 characters.",
        },
      ]}
    >
      <StyledInput
        id="edit-business_notes"
        placeholder="Soothe Internal notes"
        type="textarea"
        value={notes}
        onChange={(e) => setNotes({ notes: e.target.value })}
        suffixicon={<FeatherIcon icon="chevron-down" size="15px" />}
      />
    </Form.Item>
  );
};

function Settings({ businessData, businessId, discounts }) {
  const { userData } = useUserProfileData(businessId);
  const isSootheMember = userData?.role?.isSootheMember;
  const { permissions } = usePermissions();
  const queryClient = useQueryClient();

  const { billing, isLoading } = useGetBusinessBilling({
    id: businessId,
    status: businessData.status,
    type: businessData.billingByBusiness ? "byBusiness" : "byProperty",
  });

  const {
    billingByBusiness,
    contract,
    onsite_contract,
    discountByBusiness,
    email,
    logo,
    name,
    phone,
    status,
    notes,
    sub_domain,
    categories,
    permissions: businessPermissions,
  } = businessData;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      businessName: name,
      businessDomain: sub_domain,
      businessPhoneNumber: phone,
      businessEmail: email,
      businessLogo: logo,
      businessContract: contract,
      onsiteContract: onsite_contract,
      services: [],
      discountType: discountByBusiness ? "byBusiness" : "byProperty",
      billingType: billingByBusiness ? "byBusiness" : "byProperty",
      selectedDealTypes: categories?.map((category) => category.id) || [],
      selectedPermission: businessPermissions.map((perm) => perm.id) || [],
      notes: notes,
    }
  );
  const [volumeDiscounts, setVolumeDiscounts] = useReducer(
    (prev, next) => ({
      ...prev,
      ...next,
    }),
    {
      invoice_discounts: [],
      // should_create: false,
      any_toggled_off: false,
    }
  );
  const [shouldSaveVD, setShouldSaveVD] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [fees, setFees] = useState([]);

  useEffect(() => {
    form.resetFields();
    if (businessData) {
      form.setFieldsValue({
        businessName: name,
        "business-phone-number": phone,
        "business-email": email,
        "business-domain": sub_domain,
        bannerImage: logo,
        contractLogo: contract,
        onsiteContract: onsite_contract,
        providers: businessPermissions.map((perm) => perm.id),
        "deal-type": categories?.map((category) => category.id) || [],
        "discount-type": discountByBusiness ? "byBusiness" : "byProperty",
        "billing-type": billingByBusiness ? "byBusiness" : "byProperty",
        notes: notes,
      });
    }
  }, [businessData, form]);

  useEffect(() => {
    if (billing) {
      const { email: emails, due_in_days } = billing;
      let tempEmails = emails;
      if (!isArray(tempEmails)) {
        tempEmails = [tempEmails];
      }
      setSettings({
        emails: tempEmails,
        due_in_days: due_in_days || 15,
      });
      form.setFieldsValue({
        businessName: name,
        "billing-emails": join(tempEmails, ","),
        "payment-terms": due_in_days
      });
    }
  }, [billing]);

  const relevantVDApi = () => {
    if (shouldSaveVD && settings.billingType === "byBusiness") {
      return businessApi.postBusinessVolumeDiscounts({
        "invoice_discounts": volumeDiscounts.invoice_discounts,
        "toggled": !volumeDiscounts.any_toggled_off
      })

    } else {
      return Promise.resolve();
    }
  }

  const relevantCPFeesApi = () => {
    if (fees.length === 0) {
      return Promise.resolve();
    }
    return billingApi.postFees({
      "cancellation_policies": fees
    })
  }

  const onSaveUpdates = () => {
    if (hasErrors) {
      setLoading(false);
      openNotification(
        "Save Business",
        "Please fix the errors in Volume-Based Discounts section before proceeding.",
        "error"
      );
      return;
    }
    form.validateFields().then(() => {
      setLoading(true);
      const {
        businessPhoneNumber,
        businessLogo,
        businessContract,
        onsiteContract,
        businessEmail,
        selectedPermission,
        selectedDealTypes,
        services,
        businessName,
        discountType,
        billingType,
        businessDomain,
        notes,
        emails,
        due_in_days
      } = settings;

      const byBusiness = billingType === "byBusiness" ? true : false;

      const business = {
        name: businessName,
        phone: businessPhoneNumber,
        logo: businessLogo,
        contract: businessContract,
        onsite_contract: onsiteContract,
        email: businessEmail,
        sub_domain: businessDomain,
        notes: notes,
        ...(discountType === "byBusiness"
          ? {
            discounts: services.map((service) => {
              let newService = {
                product_id: service[1].id,
              };
              if (service[1].type === "amount") {
                newService.discount_amount = service[1].value;
              } else {
                newService.discount_percentage = service[1].value;
              }
              return newService;
            }),
          }
          : {}),
        permissions: selectedPermission,
        dealTypes: selectedDealTypes,
        byBusiness,
        ...(billingType === "byBusiness"
          ? {
            billing: {
              emails: compact(emails), due_in_days
            }
          }
          : {}),
      };
      return businessApi
        .updateBusiness(businessId, business)
        .then(async (res) => {
          await Promise.all([relevantVDApi(), relevantCPFeesApi()])
            .then(() => {
              queryClient.invalidateQueries("business");
              queryClient.removeQueries(["business"]);
              queryClient.invalidateQueries("permissions");
              setLoading(false);
              openNotification(
                "Success",
                "Business updated successfully",
                "success"
              );
              queryClient.removeQueries(["businesses"]);
            }).catch((err) => {
              setLoading(false);
              openNotification("Save Payment Settings", err.response.data.message, "error");
            });
        })
        .catch((err) => {
          setLoading(false);
          openNotification("Save Business", err.response.data.message, "error");
        })
      // await Promise.all([
      //   relevantVDApi(),
      //   // todo api calls reorder
      //   ])
    });
  };

  return (
    <Form
      form={form}
      onFinish={onSaveUpdates}
      disabled={
        !permissions?.admin?.includes("property_toggle") ||
        !permissions?.business?.includes("edit")
      }
      name="location"
      layout="vertical"
      autoComplete="off"
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
      }}
    >
      <About
        aboutData={settings}
        setAboutData={setSettings}
        form={form}
        mode="edit"
      />
      <div className={"divider mb-30"} />
      <Restricted to="billing" resource="business" fallback={<></>}>
        <Billing
          billingData={settings}
          setBillingData={setSettings}
          form={form}
          type="business"
          mode={"edit"}
        />
      </Restricted>

      {settings.billingType === "byBusiness" &&
        some(categories, (itm) => (includes(["Spa", "Corporate", "Concierge"], itm.name)))
        ?
        <>
          <Restricted to="billing" resource="business" fallback={<></>}>
            <CancellationFees
              categories={map(categories, (el) => (el.name))}
              mode="edit"
              business_id={businessId}
              setFees={setFees}
            />
          </Restricted>
        </>
        : null}
      <div className={"divider mb-30"} />
      <Permissions
        form={form}
        permissionsData={settings}
        setPermissionsData={setSettings}
      />
      <div className={"divider mb-30"} />
      <Restricted to="billing" resource="business" fallback={<></>}>
        <Discount
          discounts={discounts}
          form={form}
          discount={settings}
          setDiscount={setSettings}
          type="business"
          mode="edit"
          businessId={businessId}
        />
      </Restricted>
      <div className={"divider mb-30"} />
      {settings.billingType === "byBusiness" &&
        some(categories, (itm) => (includes(["Spa", "Corporate", "Concierge"], itm.name)))
        ? <>
          <Restricted to="billing" resource="business" fallback={<></>}>
            <VolumeDiscounts
              volumeDiscountsData={{ ...volumeDiscounts, billingType: "byBusiness", status: businessData?.status }}
              form={form}
              setVolumeDiscountsData={setVolumeDiscounts}
              mode="edit"
              businessId={businessId}
              isSootheMember={isSootheMember}
              setShouldSaveVD={setShouldSaveVD}
              setHasErrors={setHasErrors}
            />
          </Restricted>
          <div className={"divider mb-30"} />
        </> : null}
      <Notes notes={settings?.notes} setNotes={setSettings} />
      <Restricted to="property_toggle" resource="admin" fallback={<></>}>
        <Restricted to="edit" resource="business" fallback={<></>}>
          <Footer loading={loading} />
        </Restricted>
      </Restricted>
    </Form>
  );
}

export default () => {
  const { businessId } = useParams();
  const { businessData, isLoading: isBusinessLoading } = useGetBusinessById({
    id: businessId,
  });

  if (!businessData || isBusinessLoading) {
    return (
      <Skeleton
        paragraph={{
          rows: 10,
        }}
        active
      />
    );
  }
  return <Settings businessData={businessData} businessId={businessId} />;
};
