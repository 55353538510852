import {axiosInstance as axios, nodePortalAxiosInstance} from "./axiosInstance";

const teamEndpoints = {
  roles: "/roles/invite_roles",
  teams: "/teams",
  inviteTeam: "/auth/invite",
  invite_member: "/auth/invite_user",
  reinviteTeam: "/auth/invitation/resend",
  re_invite_member: "/auth/resend_invite",
  team: "/team",
  disableTeam: "disable",
  enableTeam: "enable",
 };

const team = {
  getRoles() {
    return nodePortalAxiosInstance.get(`${teamEndpoints.roles}`);
  },
  getTeams({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${teamEndpoints.teams}`, {
      params,
    });
  },
  inviteTeam(values) {
    return nodePortalAxiosInstance.post(`${teamEndpoints.invite_member}`, values);
  },
  reinviteTeam(values) {
    return nodePortalAxiosInstance.post(`${teamEndpoints.re_invite_member}`, values);
  },
  deleteTeam(id, businessId) {
    return nodePortalAxiosInstance.delete(`${teamEndpoints.teams}/${id}`, {
      data: { businessId },
    });
  },
  disableTeam(id, businessId) {
    return nodePortalAxiosInstance.post(`${teamEndpoints.teams}/${id}/${teamEndpoints.disableTeam}`, { businessId });
  },
  enableTeam(id, businessId) {
    return nodePortalAxiosInstance.post(`${teamEndpoints.teams}/${id}/${teamEndpoints.enableTeam}`, { businessId });
  },
  getTeamById({ queryKey: [, { id, businessId }] }) {
    return nodePortalAxiosInstance.get(`${teamEndpoints.teams}/${id}?businessId=${businessId}`);
  },
  updateTeam(id, updateTeam) {
    return nodePortalAxiosInstance.put(`${teamEndpoints.teams}/${id}`, updateTeam);
  },
};

export default team;
