import { useQuery } from "react-query";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";
import businessApi from "../api/businesses";

const useGetDisableDetails = (info) => {
  const { isLogin } = useTryAutoLogin();
  const { disableable_type, disableable_id } = info;

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["getDisableDetails", info],
    businessApi.getDisableDetails,
    {
      enabled: !!isLogin && !!disableable_id && !!disableable_type,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const details = get(data, "data.data", {});
  return { ...details, isError, error, isLoading, refetch };
};

export default useGetDisableDetails;
