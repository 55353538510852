import React, { lazy } from "react";
import { Route } from "react-router-dom";

import PrivateRoute from "./components/atoms/privateRoute";
import PublicRoute from "./components/atoms/publicRoute";
import ProtectedRoutes from "./components/atoms/ProtectedRoutes";
import Calendar from "./pages/spaStaffing/Calendar";
import Templates from "./pages/spaStaffing/Templates";
import BusinessDetails from "./pages/businesses/BusinessDetails";
import AddBusiness from "./pages/businesses/create/AddBusiness";
import Roles from "./pages/roles";
import NoPermissionComponent from "./PermissionsProvider/NoPermissionComponent";
import NoBusinessFound from "./PermissionsProvider/NoBusinessFound";
import Notifications from "./pages/notifications";
import AppMaintenanceHandler from "./utilities/AppMaintenanceHandler";
import PermissionHandler from "./utilities/hooks/PermissionHandler";
const AdminLayout = lazy(() => import("./layout/AdminLayout"));
const AuthLayout = lazy(() =>
  import("./components/organisms/authLayoutWrapper")
);
const MaintenanceComponent = lazy(() => import("./pages/maintenance"));
const NotFound = lazy(() => import("./pages/NotFoundPage"));
const Login = lazy(() => import("./pages/SigninPage"));
const AcceptInvitation = lazy(() => import("./pages/AcceptInvitePage"));
const Overview = lazy(() => import("./pages/overview"));
const Businesses = lazy(() => import("./pages/businesses"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Team = lazy(() => import("./pages/Team"));
const AccountSettings = lazy(() => import("./pages/AccountSettingsPage"));
const AppointmentsPage = lazy(() =>
  import("./pages/appointments/AppointmentsPage")
);
const BillingRedesigned = lazy(() => import("./pages/billing/BillingRedesigned"));
const BillingDetailsRedesigned = lazy(() => import("./pages/billing/BillingDetailsRedesigned"));
const EditTeam = lazy(() => import("./pages/EditTeam"));
const Locations = lazy(() => import("./pages/locations/view/Locations"));
const ResetPasswordPage = lazy(() =>
  import("./pages/account/ResetPasswordPage")
);
const ForgotPasswordPage = lazy(() =>
  import("./pages/account/ForgotPasswordPage")
);
const AddNewLocation = lazy(() =>
  import("./pages/locations/create/AddNewLocation")
);
const BookAppointment = lazy(() =>
  import("./pages/appointments/BookAppointment")
);
const ProviderOnboarding = lazy(() =>
  import("./pages/providers/ProviderOnboarding")
);
const AssignedProviders = lazy(() =>
  import("./pages/providers/AssignedProviders")
);

const CreateShift = lazy(() => import("./pages/spaStaffing/createShift"));

const mainRoutes = (view) => {
  return (
    <>
      <Route element={<AppMaintenanceHandler />}>
        <Route element={<PublicRoute />}>
          <Route
            exact
            path="/login"
            element={
              <AuthLayout>
                <Login />
              </AuthLayout>
            }
          />
          <Route
            exact
            path="/forgot/password"
            element={
              <AuthLayout>
                <ForgotPasswordPage />
              </AuthLayout>
            }
          />
          <Route
            exact
            path="/reset/password/:token/:email"
            element={
              <AuthLayout>
                <ResetPasswordPage />
              </AuthLayout>
            }
          />
          <Route
            exact
            path="/accept/invitation/:token/:email"
            element={
              <AuthLayout>
                <AcceptInvitation />
              </AuthLayout>
            }
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<PermissionHandler />}>
            <Route element={<NoBusinessFound />}>
              <Route element={<AdminLayout />}>
                <Route exact path="/" element={<Dashboard />} />
                <Route
                  element={<ProtectedRoutes to="read" resource="appointment" />}
                >
                  <Route
                    exact
                    path="/appointments"
                    element={<AppointmentsPage />}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="property_toggle" resource="admin" />
                  }
                >
                  <Route exact path="/overview" element={<Overview />} />
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="property_toggle" resource="admin" />
                  }
                >
                  <Route
                    element={<ProtectedRoutes to="read" resource="business" />}
                  >
                    <Route exact path="/businesses" element={<Businesses />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="property_toggle" resource="admin" />
                  }
                >
                  <Route
                    element={<ProtectedRoutes to="read" resource="role" />}
                  >
                    <Route exact path="/roles" element={<Roles />} />
                  </Route>
                </Route>
                <Route
                  element={<ProtectedRoutes to="details" resource="business" />}
                >
                  <Route
                    exact
                    path="/businesses/:businessId"
                    element={<BusinessDetails />}
                  />
                </Route>
                <Route
                  exact
                  path={"/account-settings/*"}
                  element={<AccountSettings />}
                />
                <Route
                  element={<ProtectedRoutes to="read" resource="location" />}
                >
                  <Route exact path={`/properties`} element={<Locations />} />
                  <Route
                    element={
                      <ProtectedRoutes to="update" resource="location" />
                    }
                  >
                    <Route
                      exact
                      path={`/businesses/:businessId/properties/:locationId`}
                      element={<AddNewLocation key={`/locations/edit/`} />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoutes to="create" resource="location" />
                    }
                  >
                    <Route
                      exact
                      path={`/businesses/:businessId/properties`}
                      element={<AddNewLocation key={`/locations/edit/`} />}
                    />
                  </Route>
                </Route>

                <Route
                  element={<ProtectedRoutes to="create" resource="business" />}
                >
                  <Route
                    exact
                    path={`/businesses/add`}
                    element={<AddBusiness />}
                  />
                </Route>
                <Route
                  element={<ProtectedRoutes to="tab" resource="billing" />}
                >
                  <Route exact path={`/billing`} element={<BillingRedesigned />} />
                  <Route
                    element={
                      <ProtectedRoutes to="details" resource="billing" />
                    }
                  >
                    <Route
                      exact
                      path={`/billing/:invoice_id`}
                      element={<BillingDetailsRedesigned />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes
                      to="read_assigned"
                      resource="providers"
                      both={true}
                    />
                  }
                >
                  <Route
                    exact
                    path={`/providers/assigned`}
                    element={<AssignedProviders />}
                  />
                  <Route
                    element={
                      <ProtectedRoutes
                        to="read_unassigned"
                        resource="providers"
                        both={true}
                      />
                    }
                  >
                    <Route
                      exact
                      path={`/providers/add-provider/:id`}
                      element={
                        <ProviderOnboarding
                          key={"add-provider"}
                          addProvider={true}
                        />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="property_toggle" resource="admin" />
                  }
                >
                  <Route
                    element={
                      <ProtectedRoutes
                        to="read_onboarding"
                        resource="providers"
                        both={view === "admin" ? false : true}
                      />
                    }
                  >
                    <Route
                      exact
                      path={`/providers/onboarding`}
                      element={<ProviderOnboarding key={"add-provider"} />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="read" resource="shift" both={true} />
                  }
                >
                  <Route exact path={`/staffing`} element={<Calendar />} />
                  <Route
                    element={<ProtectedRoutes to="template" resource="shift" />}
                  >
                    <Route
                      exact
                      path={`/staffing/templates/:id`}
                      element={<Templates />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoutes
                        to="create"
                        resource="shift"
                        both={true}
                      />
                    }
                  >
                    <Route
                      exact
                      path={`/staffing/create-shift/:id`}
                      element={<CreateShift />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes to="read" resource="corporate" both={true} />
                  }
                >
                  <Route exact path={`/corporate-wellness`} element={<Calendar />} />
                  <Route
                    element={<ProtectedRoutes to="template" resource="corporate" />}
                  >
                    <Route
                      exact
                      path={`/corporate-wellness/templates/:id`}
                      element={<Templates />}
                    />
                  </Route>
                  <Route
                    element={
                      <ProtectedRoutes
                        to="create"
                        resource="corporate"
                        both={true}
                      />
                    }
                  >
                    <Route
                      exact
                      path={`/corporate-wellness/create-shift/:id`}
                      element={<CreateShift />}
                    />
                  </Route>
                </Route>
                <Route element={<ProtectedRoutes to="read" resource="team" />}>
                  <Route exact path={`/team`} element={<Team />} />
                  <Route
                    element={<ProtectedRoutes to="update" resource="team" />}
                  >
                    <Route exact path={`/team/:id`} element={<EditTeam />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <ProtectedRoutes
                      to="create"
                      resource="appointment"
                      both={true}
                    />
                  }
                >
                  <Route exact path="/book" element={<BookAppointment />} />
                </Route>
                <Route
                  element={
                    <ProtectedRoutes
                      to="view"
                      resource="notification"
                      both={true}
                    />
                  }
                >
                  <Route
                    exact
                    path="/notifications"
                    element={<Notifications />}
                  />
                </Route>
              </Route>
              <Route
                path="/no-permission"
                element={<NoPermissionComponent />}
              />
              <Route path={"*"} element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/under-maintenance" element={<MaintenanceComponent />} />
    </>
  );
};
export default mainRoutes;
