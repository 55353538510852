import React from "react";
import Input from "antd/es/input";
import "antd/es/input/style";
import Button from "antd/es/button";
import "antd/es/button/style";
import AutoComplete from "antd/es/auto-complete";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./style.module.scss"

const AutoCompleteInput = (props) => {
  const {
    customComponent,
    patterns,
    patternButtons,
    width,
    onSearch,
    dataSource,
    searchVlaue,
    onSelect,
    placeholder,
    customClassName,
    allowClear,
    clearIcon,
    onClear,
    hidePrefix,
    disabled,
  } = props;

  const onSearching = (searchText) => {
    const searchUp =
      searchText && searchText[0].toUpperCase() + searchText.slice(1);
    onSearch(searchUp);
  };

  const newDataSource = dataSource.map((item, index) => ({
    key: index,
    label: item?.name || item?.full_name,
    value: item?.name || item?.full_name,
  }));

  return customComponent ? (
    <AutoComplete
      className={customClassName || styles["auto-complete-input"]}
      options={dataSource}
      style={{ width }}
      onSelect={onSelect}
      onSearch={onSearching}
      value={searchVlaue}
    >
      {customComponent}
    </AutoComplete>
  ) : patterns ? (
    <AutoComplete
      className={customClassName || styles["auto-complete-input"]}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ width: 300 }}
      style={{ width }}
      options={newDataSource}
      onSelect={onSelect}
      placeholder={placeholder}
      value={searchVlaue}
      onSearch={onSearching}
      allowClear={allowClear}
      clearIcon={clearIcon}
      onClear={onClear}
      disabled={disabled}
    >
      <Input
        prefix={hidePrefix ? null :
          patternButtons ? (
            <Button
              className="search-btn"
              style={{ marginRight: -20 }}
              type="primary"
            >
              <SearchOutlined />
            </Button>
          ) : (
            <SearchOutlined />
          )
        }
      />
    </AutoComplete>
  ) : (
    <AutoComplete
      className={customClassName || styles["auto-complete-input"]}
      options={dataSource}
      style={{ width }}
      onSelect={onSelect}
      value={searchVlaue}
      onSearch={onSearching}
      placeholder={placeholder}
    />
  );
};

AutoComplete.defaultProps = {
  width: "350px",
  placeholder: "Input here",
};

AutoComplete.propTypes = {
  customComponent: PropTypes.node,
  patterns: PropTypes.bool,
  patternButtons: PropTypes.bool,
  width: PropTypes.string,
  onSearch: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
};

export { AutoCompleteInput };
