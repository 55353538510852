import React from "react";
import intlTelInput from "intl-tel-input";
import { get, replace } from "lodash";
import "../../../../node_modules/intl-tel-input/build/css/intlTelInput.css";
import "../../../../node_modules/intl-tel-input/build/js/utils.js";
import styles from "./styles.module.scss";

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iti: null,
    };

    this.phoneChange = this.phoneChange.bind(this);
  }

  componentDidMount() {
    const input = document.querySelector(".phone-input");

    let iti = intlTelInput(input, {
      preferredCountries: ["us", "gb", "ca", "au", "de"],
      separateDialCode: true,
      nationalMode: true,
    });

    if (this.props.value) {
      iti.setNumber(this.props.value);
    }
    if (this.props.countryCode) {
      iti.setCountry(this.props.countryCode);
    }

    this.setState({ iti });

    let self = this;
    input.addEventListener("countrychange", function () {
      self.phoneChange();
    });
  }

  componentWillUnmount() {
    if (this.state.iti) {
      this.state.iti.destroy();
    }
  }

  phoneChange(e) {
    let error = this.state.iti.getValidationError();
    let number = this.state.iti.getNumber();
    let isValidNumber = this.state.iti.isValidNumber();
    let countryCode = get(this.state.iti.getSelectedCountryData(), "iso2");
    const allowedCharacters = /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-\d]+$/;

    number =
      isValidNumber && allowedCharacters.test(e?.target?.value)
        ? `+${replace(number, /\D/g, "")}`
        : number;

    this.props.onChange(number);

    this.props.onPhoneNumberChange({
      isValidNumber,
      number,
      error,
      countryCode,
    });
  }

  render() {
    return (
      <input
        type={this.props.type}
        defaultValue={this.props.value}
        onChange={this.phoneChange}
        className={`phone-input ${styles["phone-number"]}`}
      />
    );
  }
}

export default PhoneInput;
