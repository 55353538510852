import get from "lodash/get";

import { useQuery } from "react-query";
import user from "../api/user";

const useGetMaintenance = (business_id) => {
  const { data, isError, error, isLoading, refetch } = useQuery(
    ["maintenance", business_id],
    user.checkAppMaintenance,
    {
      enabled: !!business_id,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );

  const maintenanceData = get(data, "data.data");
  const other_banners = get(data, "data.other_banners");
  return { maintenanceData, other_banners, isError, error, isLoading, refetch };
};

export default useGetMaintenance;
