import React, { lazy, Suspense } from "react";

import { Heading } from "../../atoms/headings";
import SootheLogoDarker from "../../../static/img/Soothe-logo-darker.svg";
import styles from "./styles.module.scss";

const LazyVideo = lazy(() => import("./LazyVideoComponent.js"));

export const ImageOverlay = ({ title = "" }) => (
  <div className={styles["image-overlay"]}>
    <Suspense fallback={<div>Loading...</div>}>
      <LazyVideo />
    </Suspense>

    <div className={styles["padding-150"]}>
      <div>
        <img
          className={styles["width-130"]}
          width="100%"
          height="100%"
          src={SootheLogoDarker}
          alt="Soothe"
        />
      </div>
      <Heading as="h1" className={styles["margin-top-28"]}>
        {title}
      </Heading>
    </div>
  </div>
);
