import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";
const businessEndpoints = {
  businesses: "/business/all",
  disableBusiness: "disable",
  enableBusiness: "enable",
  business: "/business",
  properties: "locations",
  business_details: "/business_details",
  businessProperties: "/business/locations",
  permissions: "/business/permissions",
  update_permissions: "/business/update_permissions",
  aboutDetails: "/business",
  discounts: "/business_discounts",
  billing: "/business_billings",
  businessOverview: "/fetch_business_appointments",
  businessesOverview: "/fetch_business_appointments",
  activities: "/activity_logs",
  businessActivities: "/activity_logs",
  files: "/files",
  file: "/file",
  uploadFile: "/files",
  updateFile: "/files/update/upload",
  uploadLink: "files/link",
  updateLink: "update_link_file",
  deleteFile: "/files/delete",
  fileVersions: "/versions",
  create_link_file: "/files/create_link_file",
  volumeDiscounts: "/invoice_discounts",
};

const business = {
  getBusinesses({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(businessEndpoints.business, { params });
  },
  getDisableReasons() {
    return nodePortalAxiosInstance.get(`/disable_reasons`);
  },
  setDisableReason(data) {
    return nodePortalAxiosInstance.post(`/disable_reasons`, data);
    //   {
    //     "disableable_type": "Location",
    //     "disableable_id": 123,
    //     "disable_reason": {
    //         "cancel_future_shifts": true,
    //         "reason_text": "Closed for maintenance",
    //         "reason_id": 1
    //     }
    // }
  },
  disableBusiness(id) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.business}/${id}/${businessEndpoints.disableBusiness}`);
  },
  getDisableDetails({ queryKey: [, { disableable_type, disableable_id }] }) {
    return nodePortalAxiosInstance.get(`/disable_reasons/show_disable_details`, {
      params: { disableable_type, disableable_id }
    });
  },
  enableBusiness(id) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.business}/${id}/${businessEndpoints.enableBusiness}`);
  },
  deleteBusiness(id) {
    return nodePortalAxiosInstance.delete(`${businessEndpoints.business}/${id}`);
  },
  getBusinessById({ queryKey: [, { id }] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.business_details}?business_id=${id}`);
  },
  getBusinessProperties({
    queryKey: [, { businessId, page, per_page, sorting, search }],
  }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.business}/${businessId}/${businessEndpoints.properties}?business_id=${businessId}`, {
      params: { page, per_page, sorting, search },
    });
  },
  getAllBusinessesOverview({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(businessEndpoints.businessesOverview, { params });
  },
  getBusinessOverview({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.businessOverview}`, {
      params,
    });
  },
  getActivities({ queryKey: [, { page }] }) {
    return nodePortalAxiosInstance.get(businessEndpoints.activities, {
      params: { page },
    });
  },
  getBusinessActivities({ queryKey: [, { businessId, page }] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.businessActivities}`, {
      params: { page, businessId },
    });
  },
  getPermissions() {
    return nodePortalAxiosInstance.get(`${businessEndpoints.permissions}`);
  },
  updatePermissions(id, permissions) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.update_permissions}?business_id=${id}`, permissions);
  },
  AddAboutDetails(data) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.aboutDetails}`, data);
  },
  updateAboutDetails(id, data) {
    return nodePortalAxiosInstance.put(`${businessEndpoints.aboutDetails}/${id}`, data);
  },
  updateBusiness(id, data) {
    return nodePortalAxiosInstance.put(`${businessEndpoints.aboutDetails}/${id}`, data);
  },
  getDisconts({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.discounts}?id=${id}`);
  },
  updateDiscounts(id, discounts) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.discounts}?id=${id}`, discounts);
  },
  getBilling({ queryKey: [, id] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.billing}?id=${id}`);
  },
  updateBilling(id, billings) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.billing}?id=${id}`, billings);
  },
  getBusinessFiles({ queryKey: [, params] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.files}?business_id=${params.businessId}`, {
      params,
    });
  },
  getFile({ queryKey: [, { businessId, fileId }] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.files}/${fileId}`, {
    });
  },
  uploadFile(data) {
    return nodePortalAxiosInstance.post(
      `${businessEndpoints.uploadFile}?business_id=${data.businessId}`,
      data
    );
  },
  updateFile(data) {
    return nodePortalAxiosInstance.put(
      `${businessEndpoints.files}/${data.file_id}?business_id=${data.businessId}`,
      data
    );
  },
  uploadLink(data) {
    return nodePortalAxiosInstance.post(
      `${businessEndpoints.create_link_file}?business_id=${data.businessId}`,
      data
    );
  },
  updateLink(data) {
    return nodePortalAxiosInstance.post(
      `${businessEndpoints.files}/${data.file_id}/${businessEndpoints.updateLink}`,
      data
    );
  },
  deleteFile(businessId, fileId) {
    return nodePortalAxiosInstance.delete(`${businessEndpoints.files}/${fileId}`, {
      data: { file_id: fileId },
    });
  },
  getFileVersions({ queryKey: [, { businessId, fileId }] }) {
    return nodePortalAxiosInstance.get(`${businessEndpoints.files}/${fileId}/${businessEndpoints.fileVersions}/`, {
      params: { file_id: fileId },
    });
  },
  getBusinessVolumeDiscounts({ queryKey: [, id] }) {
    const params = id ? `?discountable_type=business&discountable_id=${id}` : '';
    return nodePortalAxiosInstance.get(`${businessEndpoints.volumeDiscounts}${params}`);
  },
  postBusinessVolumeDiscounts(data) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.volumeDiscounts}`,
      data
    );
  },
  patchBusinessVolumeDiscounts(data) {
    return nodePortalAxiosInstance.post(`${businessEndpoints.volumeDiscounts}/update`,
      data
    );
  },
};

export default business;
