import React from "react";
import Radio from "antd/es/radio";
import "antd/es/radio/style";
import { capitalize } from "lodash";
import styles from "./styles.module.scss"

export const Filter = ({
  filterBy,
  onChange,
  defaultValue,
  filterKey,
  value,
  style,
}) => {
  return (
    <div className={styles["filter-menu"]} style={style}>
      <span className={styles["toolbox-menu-title"]}> {`${filterBy}:`}</span>
      <Radio.Group
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      >
        <Radio.Button value="all">All</Radio.Button>
        {[...new Set(filterKey)].map((value) => {
          return (
            <Radio.Button key={value} value={value}>
              {capitalize(value)}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </div>
  );
};
