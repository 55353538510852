import team from "./api/teams";
import get from "lodash/get";
const teamsProvider = {
  getTeams: async (
    page = 0,
    size = 10,
    selectedStatus = "all",
    search = "",
    location,
    businessId,
    callBackFunc
  ) => {
    try {
      let defaultParams = {
        page: page,
        size: size,
        search: search,
        status: selectedStatus,
        location: location,
      };
      if (businessId) {
        defaultParams = { ...defaultParams, businessId };
      }
      const { data, statusText, status } = await team.getTeams(defaultParams);
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc(data.data.totalPages, data.data.totalItems, data.data.rows);
    } catch (err) {
      console.log(err);
    }
  },
  createTeam: async (values, businessId, setLoading, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.inviteTeam({
        ...values,
        businessId,
      });
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      setLoading(false);
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
  reinviteTeam: async (values, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.reinviteTeam(values);
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
  deleteTeam: async (id, businessId, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.deleteTeam(
        id,
        businessId
      );
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }

      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
  disableTeam: async (id, businessId, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.disableTeam(
        id,
        businessId
      );
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
  enableTeam: async (id, businessId, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.enableTeam(
        id,
        businessId
      );
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
  getRoles: async (callBackFunc) => {
    try {
      const { data, statusText, status } = await team.getRoles();
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc(data.data.rows);
    } catch (err) {
      console.log(err);
    }
  },
  getTeamById: async (id, businessId, callBackFunc) => {
    try {
      const { data, statusText, status } = await team.getTeamById(
        id,
        businessId
      );

      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }

      callBackFunc(get(data, "data", []));
    } catch (err) {
      callBackFunc(false);
      console.log(err);
    }
  },
  updateTeam: async (id, updateTeam, callBackFunc) => {
    try {
      const { data, status, statusText } = await team.updateTeam(
        id,
        updateTeam
      );
      if (status < 200 || status >= 300) {
        throw new Error(statusText);
      }
      callBackFunc("success", "Success", data.message, false);
    } catch (err) {
      callBackFunc("error", "Error", err.response.data.message, false);
    }
  },
};

export default teamsProvider;
