import React, { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import Row from "antd/es/row";
import "antd/es/row/style";
import Col from "antd/es/col";
import "antd/es/col/style";
import Table from "antd/es/table";
import "antd/es/table/style";
import Pagination from "antd/es/pagination";
import "antd/es/pagination/style";
import Menu from "antd/es/menu";
import Tooltip from "antd/es/tooltip";
import "antd/es/tooltip/style";
import FeatherIcon from "feather-icons-react";
import Form from "antd/es/form";
import "antd/es/form/style";
import Radio from "antd/es/radio";
import TextArea from "antd/lib/input/TextArea";

import { Cards } from "../../../../atoms/cards/Cards";
import { DropdownBase } from "../../../../atoms";
import styles from "./PropertiesTable.module.scss";
import { capitalize } from "lodash";
import openNotification from "../../../../../utilities/openNotification";
import {
  deleteLocation,
  disableLocation,
  enableLocation,
} from "../../../../../services/locations";
import { useQueryClient } from "react-query";
import DeleteModal from "../../../../molecules/locations/DeleteModal";
import EllipsisLabel from "../../../../atoms/ellipsisLabel/EllipsisLabel";
import Restricted from "../../../../../PermissionsProvider/Restricted";
import useGetDisableReasons from "../../../../../utilities/hooks/useGetDisableReasons";
import ConfirmModal from "../../../ConfirmModal";
import businessApis from "../../../../../utilities/api/businesses";
import DisableDetailsModal from "../../../DisableDetailsModal";

function PropertiesTable({
  properties,
  isLoading,
  totalItems,
  page,
  perPage,
  onPageChange,
  onPageSizeChange,
  business,
  refetch,
}) {
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [targetLocation, setTargetLocation] = useState();
  const [confirmModal, setConfirmModal] = useReducer(
    (prev, next) => ({ ...next }),
    {
      isOpen: false,
      isLoading: false,
      action: "",
    }
  );
  const [form] = Form.useForm();
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [reasonToDisable, setReasonToDisable] = useState({});
  const [cancel_future_shifts, setCancelFutureShifts] = useState(true);
  const [disableDetailsModal, setDisableDetailsModal] = useState(false);

  const { reasons } = useGetDisableReasons();

  const handleDisable = (locationId) => {
    setConfirmModal({ isLoading: true });
    disableLocation(locationId)
      .then(() => {
        businessApis.setDisableReason({
          disableable_type: "Location",
          disableable_id: locationId,
          disable_reason: {
            cancel_future_shifts,
            reason_text: additionalDetails,
            reason_id: reasonToDisable.id
          }
        }).then(() => {
          openNotification(
            "Disable Location",
            "Location successfully disabled",
            "success"
          );
          setConfirmModal({ isOpen: false, isLoading: false });
          setSelectedLocationId(null);
          form.resetFields();
          refetch();
          queryClient.removeQueries("paginated");
          queryClient.removeQueries("locations");
        }).catch((err) => {
          openNotification(
            "Disable Location",
            err.response.data.message,
            "error"
          );
          setConfirmModal({ isLoading: false });
        });
      })
      .catch((err) => {
        openNotification(
          "Disable Location",
          err.response.data.message,
          "error"
        );
        setConfirmModal({ isLoading: false });
      });
  };
  const handleEnable = (id) => {
    enableLocation(id)
      .then(() => {
        openNotification(
          "Enable Location",
          "Location successfully enabled",
          "success"
        );
        refetch();
        queryClient.removeQueries("paginated");
        queryClient.removeQueries("locations");
      })
      .catch((err) => {
        openNotification("Enable Location", err.response.data.message, "error");
      });
  };

  const onDeleteClick = (location) => {
    setIsDeleteModalOpen(true);
    setTargetLocation(location);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTargetLocation(null);
  };

  const handleDeleteLocation = () => {
    deleteLocation(targetLocation?.id)
      .then(() => {
        openNotification(
          "Delete Location",
          `Location "${targetLocation?.name}" successfully delete`,
          "success"
        );
        refetch();
        queryClient.removeQueries("paginated");
        queryClient.removeQueries("locations");
      })
      .catch((err) => {
        openNotification("Delete Location", err.response.data.message, "error");
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const generateDataSource = () =>
    properties?.map((value, index) => {
      const { id, name, dealType, disabled, providerCount, status } = value;

      return {
        key: index,
        name:
          name.length > 29 ? (
            <Tooltip title={name} placement="topLeft" color={"#fff"} key={id}>
              <EllipsisLabel>{name}</EllipsisLabel>
            </Tooltip>
          ) : (
            name
          ),
        dealType: dealType,
        providers: `${providerCount} Providers`,
        status: (
          <span
            className={`status ${status === "live"
              ? "Success"
              : status === "pending"
                ? "warning"
                : status === "disabled"
                  ? "disabled"
                  : status === "draft"
                    ? "in-progress"
                    : "error"
              }`}
          >
            {disabled ? "Disabled" : capitalize(status)}
          </span>
        ),
        action: (
          <DropdownBase
            className="actions-dropdown"
            classNameC="actions-dropdown-content"
            action={["click"]}
            content={
              <Menu
                items={[
                  {
                    label: (
                      <Restricted
                        to="update"
                        resource="location"
                        fallback={<></>}
                      >
                        <Link
                          to={
                            status === "draft"
                              ? `/businesses/${business.id}/properties`
                              : `/businesses/${business.id}/properties/${id}`
                          }
                          state={{
                            mode: "business",
                            locationId: id,
                            isDraft: status === "draft",
                          }}
                        >
                          <FeatherIcon icon="edit-2" size={18} /> Edit
                        </Link>
                      </Restricted>
                    ),
                    key: "1",
                  },
                  {
                    label:
                      status === "disabled" || status === "pending" ? (
                        <Restricted
                          to="enable"
                          resource="location"
                          fallback={<></>}
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              handleEnable(id);
                            }}
                          >
                            <FeatherIcon icon="check-square" size={18} /> Enable
                          </Link>
                        </Restricted>
                      ) : (
                        <Restricted
                          to="disable"
                          resource="location"
                          fallback={<></>}
                        >
                          <Link
                            to="#"
                            onClick={(e) => {
                              setSelectedLocationId(id);
                              setConfirmModal({
                                isOpen: true,
                                action: "disable",
                              });
                            }}
                          >
                            <FeatherIcon icon="x-square" size={18} /> Disable
                          </Link>
                        </Restricted>
                      ),
                    key: "2",
                  },
                  ...status === "disabled" ? [{
                    label: (<Restricted
                      to="disable"
                      resource="location"
                      fallback={<></>}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          setSelectedLocationId(id);
                          setDisableDetailsModal(true)
                        }}
                      >
                        <FeatherIcon icon="file-text" size={18} />
                        View disable details
                      </Link>
                    </Restricted>
                    ),
                    key: "4",
                  }] : [],
                  {
                    label: (
                      <Restricted
                        to="delete"
                        resource="location"
                        fallback={<></>}
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            onDeleteClick(value);
                          }}
                        >
                          <FeatherIcon icon="trash-2" size={18} />
                          Delete
                        </Link>
                      </Restricted>
                    ),
                    key: "3",
                  },
                ]}
              />
            }
          >
            <Link to="#">
              <FeatherIcon icon="more-vertical" size={18} />
            </Link>
          </DropdownBase>
        ),
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Deal Type",
      dataIndex: "dealType",
      key: "dealType",
    },
    {
      title: "Providers",
      dataIndex: "providers",
      key: "providers",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <Row gutter={25} className="mt-20">
        <Col xs={24}>
          <div className={styles["table-container"]}>
            <Cards headless border={false}>
              <div className="table-responsive">
                <Table
                  loading={isLoading}
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                  pagination={false}
                  dataSource={generateDataSource()}
                  columns={columns}
                  size="middle"
                />
              </div>
            </Cards>
          </div>
        </Col>
        <Col xs={24} className="pb-30">
          <Pagination
            style={{ textAlign: "end" }}
            current={page + 1}
            pageSize={perPage}
            showSizeChanger={true}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            total={totalItems}
            hideOnSinglePage={totalItems < 10 ? true : false}
          />
        </Col>
      </Row>
      <DeleteModal
        isVisible={isDeleteModalOpen}
        locationName={targetLocation?.name}
        onCancel={closeDeleteModal}
        onSubmit={handleDeleteLocation}
      />
      {confirmModal.action === "disable" && (
        <ConfirmModal
          title="Disable this location"
          text={<>
            <div className="mb-24">This will stop this property from creating and completing appointments from all deal types and may incur cancellation fees on upcoming appointments.</div>
            <Form
              form={form}
              name="reasons"
              onFinish={() => { }}
            >
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Select a reason*</p>
              <Form.Item
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "Please select a reason",
                  },
                ]}
              >
                <Radio.Group>
                  <div className="ant-radio-btn-vertical">
                    {reasons.map((rsn, index) => (
                      <Radio
                        key={rsn.id}
                        value={rsn.id}
                        onChange={(e) => {
                          setReasonToDisable({
                            id: e.target.value,
                            index,
                          })
                        }}
                      >
                        {rsn.description}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Cancel upcoming shifts?*</p>
              <Form.Item
                name="cancel_future_shifts"
              >
                <Radio.Group
                  defaultValue={cancel_future_shifts}
                  value={cancel_future_shifts}
                  onChange={(e) => {
                    setCancelFutureShifts(e.target.value)
                  }}>
                  <div className="ant-radio-btn-vertical">
                    <Radio value={true}>Yes, cancel all shifts with a start time not within 3 days</Radio>
                    <Radio value={false}>No, keep all upcoming shifts</Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
              <p style={{
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "500",
                marginBottom: "10px",
              }}>Additional Details*</p>
              <Form.Item
                name="additional-details"
                rules={[
                  {
                    required: true,
                    message: "Please add additional details",
                  },
                ]}>
                <TextArea
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  rows={4}
                  style={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}
                  maxLength={255}
                  showCount>
                </TextArea>
              </Form.Item>
            </Form>
          </>}
          buttons={[
            {
              className: "my-10",
              size: "small",
              type: "danger",
              outlined: true,
              text: "Close without saving",
              onClick: () => setConfirmModal({ isOpen: false }),
            },
            {
              size: "small",
              type: "primary",
              loading: confirmModal.isLoading,
              text: "Submit and disable this business",
              onClick: () => {
                form.validateFields().then((values) => {
                  handleDisable(selectedLocationId);
                })
              },
            },
          ]}
          isOpen={confirmModal.isOpen}
        />
      )}
      {disableDetailsModal && (
        <DisableDetailsModal
          disableable_type="Location"
          disableable_id={selectedLocationId}
          isOpen={disableDetailsModal}
          onClose={() => {
            setDisableDetailsModal(false)
            setSelectedLocationId(null)
          }}
        />
      )}
    </>
  );
}

export default PropertiesTable;
